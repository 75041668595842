import Loader from '../components/_loader';
import { dashCaseToCamelCase } from '../components/_utils';

const dataLoaderSelector = 'loader-event';
const defaultEvent = 'click';

export default function (dataSelector = dataLoaderSelector) {
  const loaderSelector = `[data-${dataSelector}]`;
  const listenerAttribute = 'listener';
  const dataAttributeSelector = dashCaseToCamelCase(dataSelector);
  const loaderElements = document.querySelectorAll(loaderSelector);
  const loader = new Loader();

  function setEventAttribute(element, event) {
    element.setAttribute(listenerAttribute, event);
  }

  [].forEach.call(loaderElements, (loaderElement) => {
    const formElement = loaderElement.closest('form');
    // verify if the element has the data attribute defined
    const hasDataProperty = Object.prototype.hasOwnProperty.call(
      loaderElement.dataset,
      dataAttributeSelector,
    );
    const loaderElementSelector = hasDataProperty && loaderElement.dataset[dataAttributeSelector];
    const eventType = loaderElementSelector || defaultEvent;
    if (loaderElement.getAttribute(listenerAttribute) === eventType) return;
    // Distinguish the form submit buttons by the defined type
    if (
      formElement
      && (loaderElement.hasAttribute('type') && loaderElement.getAttribute('type') === 'submit')
    ) {
      loaderElement.addEventListener(eventType, () => {
        if (formElement.checkValidity()) {
          loader.show();
        }
      });
    } else {
      loaderElement.addEventListener(eventType, () => {
        loader.show();
      });
    }
    setEventAttribute(loaderElement, eventType);
  });
}
