export default function lazyLoader() {
  /**
   * Lazy load images.
   */
  [].forEach.call(document.querySelectorAll('.js--lazy-load[data-src]'), (img) => {
    const imgElem = img;
    imgElem.setAttribute('src', imgElem.getAttribute('data-src'));
    /**
     * We have to remove the data-src, or the image will remain hidden
     */
    imgElem.onload = function lazyLoadRemoveDataSrc() {
      imgElem.removeAttribute('data-src');
    };
  });
}
