function readCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

function setCookie(element, cookieName) {
  const state = this.readCookie(cookieName);

  const date = new Date();
  const expirationTime = 63072000000;
  date.setTime(date.getTime() + expirationTime);

  element.addEventListener('click', () => {
    if (state !== 'true') {
      document.cookie = `${cookieName}=true; expires=${date.toUTCString()}`;
    } else {
      document.cookie = `${cookieName}=false; expires=${date.toUTCString()}`;
    }
  });
}

function fadeOut(fadeTarget, timeout = 5000) {
  const fadingElem = fadeTarget;
  setTimeout(() => {
    const intervalId = setInterval(() => {
      for (let i = 0; i < fadingElem.length; i++) {
        const fadingOpacity = fadingElem[i].style.opacity;
        let currentOpacity = fadingOpacity ? Number(fadingOpacity) : 1;

        if (currentOpacity > 0) {
          currentOpacity -= 0.1;
        } else {
          fadingElem[i].removeAttribute('hidden', '');
          fadingElem[i].setAttribute('hidden', '');
          clearInterval(intervalId);
        }

        fadingElem[i].style.opacity = `${currentOpacity}`;
      }
    }, 100);
  }, timeout);
}

function dashCaseToCamelCase(stringToConvert) {
  return (
    (stringToConvert
      && stringToConvert
        .toLowerCase()
        .replace(/-([a-z])/, m => m.toUpperCase())
        .replace(/-/, ''))
    || ''
  );
}

function debounce(func, wait, immediate) {
  let timeout;

  return function () {
    const context = this;
    const args = arguments; // eslint-disable-line
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

function throttle(fn, wait) {
  let time = Date.now();
  return function () {
    if (time + wait - Date.now() < 0) {
      fn();
      time = Date.now();
    }
  };
}

export {
  fadeOut, readCookie, setCookie, dashCaseToCamelCase, debounce, throttle,
};
