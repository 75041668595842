import { fadeOut } from '../components/_utils';

export function init() {
  const alertBoxElem = document.querySelectorAll('.alert:not(.cookies-warning)');
  const alertBoxCloseElem = document.querySelectorAll('.close');
  fadeOut(alertBoxElem);
  for (let i = 0; i < alertBoxCloseElem.length; i++) {
    alertBoxCloseElem[i].addEventListener('click', closeAlert);
  }
  function closeAlert(event) {
    const element = event.currentTarget;
    if (element) {
      const alertBoxToBeClosed = element.closest('.alert');
      if (alertBoxToBeClosed) {
        alertBoxToBeClosed.style.display = 'none';
      }
    }
  }
}

export default init;
