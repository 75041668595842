import Loader from './components/_loader';
import showLoader from './global/loader-on-event';
import alert from './alerts/alert';
import ready from './components/_ready';
import cookies from './components/_cookies';
import lazyLoader from './components/_lazy-load';
import idle from './components/_idle';

ready(() => {
  const { pathname } = window.location;
  alert();

  setTimeout(lazyLoader, 0);
  Loader.clear();
  showLoader();
  cookies();
  const envBox = document.getElementById('env-box');
  if (envBox) {
    return;
  }

  if (pathname !== '/' && pathname !== '/password/first-login') {
    idle();
  }
});
