export default class Loader {
  targetEl;

  targetElPosition = 'beforeend';

  hideClass = 'hidden';

  injected;

  // a generated id, in case there are multiple loaders
  loaderId;

  _loaderElement;

  static className = 'loader-wrapper';

  // loader template
  element;

  constructor(options) {
    this.loaderId = `loader--${Math.random()
      .toString(36)
      .substr(2, 9)}`;
    this.injected = false;
    const { className } = Loader;
    this.element = `
        <div id="${this.loaderId}" class="${className} ${this.hideClass}">
          <div class="loader">
            <svg width="200" height="200" viewBox="0 0 100 100">
              <polyline class="stroke-still" points="0,0 100,0 100,75" stroke-width="40" stroke-dasharray="75,25" fill="none"></polyline>
              <polyline class="stroke-still-2" points="0,25 0,100 100,100" stroke-width="40" stroke-dasharray="80,20" fill="none"></polyline>
              <polyline class="stroke-animation" points="0,0 100,0 100,75" stroke-width="40" fill="none"></polyline>
              <polyline class="stroke-animation-2" points="0,25 0,100 100,100" stroke-width="40" fill="none"></polyline>
            </svg>
          </div>
        </div>
    `;
    let targetSelector = 'body';
    if (options && options.targetElSelector) {
      targetSelector = options.targetElSelector;
    }
    try {
      this.targetEl = document.querySelector(targetSelector);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Invalid selector', e);
    }
  }

  inject() {
    if (this.targetEl) {
      this.injected = true;
      this.targetEl.insertAdjacentHTML(this.targetElPosition, this.element);
    }
  }

  show() {
    if (!this.injected) {
      this.inject();
    }
    this.injected = !this.injected;
    this.loaderElement().classList.remove(this.hideClass);
  }

  hide() {
    this.injected = !this.injected;
    const loaderElement = this.loaderElement();
    if (loaderElement) {
      this.loaderElement().classList.add(this.hideClass);
    }
  }

  loaderElement() {
    if (!this._loaderElement) {
      this._loaderElement = document.getElementById(this.loaderId);
    }
    return this._loaderElement;
  }

  /**
   * This method is required for clearing any loaders present in the page.
   * It is required due to a Safari behaviour where if the user pressed back (browser)
   * the loader would be visible on page load.
   */
  static clear() {
    const { className } = Loader;
    const loader = document.querySelector(`.${className}`);
    if (loader && loader.parentNode) {
      loader.parentNode.removeChild(loader);
    }
  }
}
