import { throttle } from './_utils';
import Loader from './_loader';

export default function idle() {
  const timeBox = document.querySelector('.alert-box');
  const timeElement = document.querySelector('.alert-box .alert-time');
  const idleMax = 240000; // 4 minutes or 240.000 miliseconds
  const idleMin = 10000; // 10 seconds
  const idleInterval = idleMax * 0.1; // 10% of the total time
  const mouseMove = idleInterval > idleMin ? idleInterval : idleMin;
  let idleWarning = idleMax * 0.75; // 75% of the total time
  let timerCurrent = idleMax * 0.25;
  let timerPopUp;
  let timerIdle;
  let timerWarning;

  startTimeouts();
  const loader = new Loader();

  document.addEventListener('mousemove', throttle(refreshToken.bind(null, timerIdle), mouseMove));
  document.addEventListener('touchmove', throttle(refreshToken.bind(null, timerIdle), mouseMove));

  function warningIdle() {
    const seconds = 1000;
    timerPopUp = setInterval(updateClock, seconds);
  }

  function updateClock() {
    if (!timerCurrent) return;
    timerCurrent -= 1000;

    const timerInSeconds = timerCurrent / 1000;
    const minutes = Math.floor(timerInSeconds / 60);
    const seconds = timerInSeconds % 60;

    timeElement.innerHTML = `${minutes}:${seconds}`;
    timeBox.style.opacity = 1;
    timeBox.removeAttribute('hidden');
  }

  function startTimeouts() {
    timerIdle = setTimeout(logout, idleMax);
    timerWarning = setTimeout(warningIdle, idleWarning);
  }

  function refreshToken() {
    clearTimeout(timerIdle);
    clearTimeout(timerWarning);
    clearTimeout(timerPopUp);
    startTimeouts();
    timerCurrent = idleMax * 0.25;
    idleWarning = idleMax * 0.75;
    timeBox.setAttribute('hidden', '');

    fetch('/refresh-session', { method: 'GET' }).then((response) => {
      if (response.redirected) {
        logout();
      }
    });
  }

  function logout() {
    loader.show();
    window.location.href = '/logout';
  }
}
