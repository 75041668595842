import { readCookie } from './_utils';

export default function cookies() {
  /**
   * Read and/or set cookies
   */
  const confirm = document.querySelector('.cookies-warning .close');
  const area = document.querySelector('.cookies-warning');
  const checkCookie = readCookie('cookieAccepted');

  if (!confirm) {
    return;
  }

  if (checkCookie !== 'true') {
    area.style.display = 'block';
  }

  confirm.addEventListener('click', hide);

  function hide() {
    const date = new Date();
    // (days)730 * 24 * 60 * 60 * 1000
    const expirationTime = 63072000000;

    date.setTime(date.getTime() + expirationTime);

    document.cookie = `cookieAccepted=true; expires=${date.toUTCString()}`;

    if (area) area.style.display = 'none';
  }
}
